import { themeUtils } from '@/lib/utils'
import { i18n }       from '@/lang/lang'

// eslint-disable-next-line no-unused-vars
// const PlaceHolder = () => themeUtils.importThemeComponent('components/common/PlaceHolder')
const Home = () => themeUtils.importThemeComponent('views/home/Home')
const About = () => themeUtils.importThemeComponent('views/about/About')
const Press = () => themeUtils.importThemeComponent('views/press/Press')

export const AppRoutes = {
  SimpleRoutes: [
    {
      name    : 'Default',
      path    : '*',
      redirect: '/' + i18n.locale
    }
  ],

  LangRoutes: [
    {
      name     : 'Home',
      path     : '',
      component: Home,
      props    : true,
      meta     : {
        layout: 'default'
      }
    },
    {
      name     : 'AboutUs',
      path     : 'about-us',
      component: About,
      props    : true,
      meta     : {
        layout: 'default'
      }
    },
    {
      name     : 'Press',
      path     : 'press',
      component: Press,
      props    : true,
      meta     : {
        layout: 'default'
      }
    }
  ]
}

export default AppRoutes
