<template>
  <v-container fluid>
    <v-row>
      <v-col
        class="text-center"
        cols="12"
      >
        <h1
          :class="headerTitleClass"
          class="synthesis-brand-lightest-blue--text mb-10"
        >
          Press
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          class="synthesis-ui-blue-07 pa-4 pa-md-8 pa-lg-8 synthesis-section-border-radius"
          color="transparent"
          flat
        >
          <v-container
            class="synthesis-brand-dark-blue--text text-left"
            fluid
          >
            <v-row>
              <v-col
                cols="12"
                md="8"
                offset-md="2"
              >
                <template v-for="(article, articleIdx) in articles">
                  <v-card
                    :key="`article-${articleIdx}`"
                    class="mb-8 mb-md-16"
                    flat
                  >
                    <v-img
                      v-if="article.featured_media && getArticleImage(article, 'full')"
                      :src="getArticleImage(article, 'full').source_url"
                      max-height="500"
                    />

                    <v-card-text>
                      <v-chip
                        class="ma-4 white--text float-right mt-6"
                        color="synthesis-ui-blue-01"
                        label
                        small
                      >
                        {{ getArticleDate(article) }}
                      </v-chip>

                      <div
                        :class="sectionTitleClass"
                        class="py-4 synthesis-brand-dark-blue--text text-left font-weight-regular"
                        v-html="sanitizeText(article.title.rendered)"
                      />

                      <div
                        :class="sectionSubTitleClass"
                        class="synthesis-brand-dark-blue--text text-left font-weight-regular"
                        v-html="sanitizeText(article.content.rendered)"
                      />
                    </v-card-text>
                  </v-card>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta     from '@/mixins/i18n/i18nRouteMeta'
import CssClassFontSizes from '@/mixins/cssClassFontSizes'
import axios             from 'axios'

export default {
  name      : 'Press',
  components: {},
  directives: {},
  mixins    : [i18nRouteMeta, CssClassFontSizes],
  props     : {},
  dataStore : {},
  enums     : {},
  data () {
    return {
      articles: []
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    sanitizeText (text) {
      const regex = /((\[(.*?)\])|(\[\/(.*?)\]))/gm

      return text.replaceAll(regex, '')
    },

    getArticleImage (article, size = 'large') {
      const featuredMediaId = article.featured_media
      const media = article?._embedded['wp:featuredmedia']?.find(media => media.id === featuredMediaId) || null

      return media?.media_details?.sizes[size] || media?.media_details?.sizes?.full || ''
    },

    getArticleDate (article) {
      if (!article) return ''

      const dateStr = article?.date || ''
      const [year, month, day] = dateStr.substring(0, 10)?.split('-')

      return `${ String(day).padStart(2, '0') }/${ String(month).padStart(2, '0') }/${ year }`
    },

    getArticleTime (article) {
      if (!article) return ''

      const dateStr = article?.date || ''
      const [hour, minute] = dateStr.substring(11, dateStr.length)?.split(':')

      return `${ String(hour).padStart(2, '0') }:${ String(minute).padStart(2, '0') }`
    },

    async getData () {
      const url = '/press/press.json'
      // https://nordicapis.com/10-free-to-use-cors-proxies/
      // const url = 'https://corsproxy.io/?' + encodeURIComponent('https://mavenglobal.co.uk/wp-json/wp/v2/posts/?_embed&orderby=date&order=desc&page=1&per_page=100')
      // const url = 'https://thingproxy.freeboard.io/fetch/' + encodeURIComponent('https://mavenglobal.co.uk/wp-json/wp/v2/posts/?_embed&orderby=date&order=desc&page=1&per_page=100')
      const config = {
        headers: {
          'Access-Control-Allow-Origin'  : '*',
          'Access-Control-Expose-Headers': 'x-wp-total'
        }
      }

      const result = await axios.get(url, config)
      this.articles = result?.data || []
    }
  }
}
</script>

<style scoped>

</style>
