<template>
  <v-container fluid>
    <v-row>
      <v-col
        class="text-center"
        cols="12"
      >
        <h1
          :class="headerTitleClass"
          class="synthesis-brand-lightest-blue--text mb-10"
        >
          About Us
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          class="synthesis-ui-blue-02 pa-4 pa-md-8 pa-lg-8 synthesis-section-border-radius fill-height"
          flat
        >
          <v-container
            class="text-center"
            fluid
          >
            <v-row>
              <v-col cols="12">
                <div
                  class="d-inline-block"
                  style="max-width: 700px;"
                >
                  <h1
                    :class="sectionTitleClass"
                    class="synthesis-brand-lightest-blue--text"
                  >
                    What Makes Us Unique
                  </h1>

                  <h2
                    :class="headerSubTitleClass"
                    class="synthesis-brand-lightest-blue--text py-6"
                  >
                    Our people and our technology. From our exceptional board of advisors to the collective experience of senior management and our talented team.
                  </h2>
                </div>
              </v-col>

              <v-col cols="12">
                <v-card
                  class="d-inline-flex"
                  color="transparent"
                  flat
                >
                  <v-img
                    gradient="180deg, rgba(33, 58, 130, 0) 60%, rgba(33, 58, 130, 1) 95%"
                    height="auto"
                    max-width="1200"
                    src="https://mavenglobal.co.uk/wp-content/uploads/2020/02/business-office.jpg"
                    width="100%"
                  />
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <div
                  class="d-inline-block"
                  style="max-width: 1200px;"
                >
                  <v-container
                    class="justify-space-between synthesis-brand-lightest-blue--text"
                    fluid
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <div class="px-4">
                          <v-avatar
                            color="synthesis-brand-lightest-blue"
                            size="92"
                          >
                            <v-icon
                              color="synthesis-ui-blue-02"
                              size="56"
                            >
                              mdi-head-flash-outline
                            </v-icon>
                          </v-avatar>

                          <div
                            :class="sectionSubTitleClass"
                            class="font-weight-bold py-4"
                          >
                            Dynamic Innovation
                          </div>

                          <div>Maven’s proprietary models and risk frameworks utilize latest technologies including AI and Machine Learning</div>
                        </div>
                      </v-col>

                      <v-col
                        cols="12"
                        md="4"
                      >
                        <div class="px-4">
                          <v-avatar
                            color="synthesis-brand-lightest-blue"
                            size="92"
                          >
                            <v-icon
                              color="synthesis-ui-blue-02"
                              size="56"
                            >
                              mdi-web
                            </v-icon>
                          </v-avatar>

                          <div
                            :class="sectionSubTitleClass"
                            class="font-weight-bold py-4"
                          >
                            Global Reach
                          </div>

                          <div>Maven projects span 5 continents<br>and 47 countries</div>
                        </div>
                      </v-col>

                      <v-col
                        cols="12"
                        md="4"
                      >
                        <div class="px-4">
                          <v-avatar
                            color="synthesis-brand-lightest-blue"
                            size="92"
                          >
                            <v-icon
                              color="synthesis-ui-blue-02"
                              size="56"
                            >
                              mdi-image-filter-center-focus-weak
                            </v-icon>
                          </v-avatar>

                          <div
                            :class="sectionSubTitleClass"
                            class="font-weight-bold py-4"
                          >
                            Client Focus
                          </div>

                          <div>Whether a Government, a Corporation or a Private Capital Fund, Maven clients stay with us for the long term</div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          class="synthesis-brand-lightest-blue pa-4 pa-md-8 pa-lg-8 synthesis-section-border-radius fill-height"
          flat
        >
          <v-container
            class="text-center"
            fluid
          >
            <v-row>
              <v-col cols="12">
                <div
                  class="d-inline-block"
                  style="max-width: 1200px;"
                >
                  <v-container
                    class="synthesis-brand-dark-blue--text text-left"
                    fluid
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        md="5"
                      >
                        <div>
                          <div
                            :class="sectionTitleClass"
                            class="py-4 text-left"
                          >
                            Social Impact
                          </div>

                          <div>A key element of Maven Global’s mission is social impact. Whether we work with development funds on helping local economies or global charities to most efficiently distribute international donations, it is important to for us to feel we are making a small difference.</div>
                        </div>

                        <v-divider class="mt-6 hidden-md-and-up" />
                      </v-col>

                      <v-col
                        cols="12"
                        md="5"
                        offset-md="2"
                      >
                        <div class="float-right">
                          <p>
                            <strong class="maven_term">Maven</strong> (/meɪv(ə)n/) n.<br>
                            INFORMAL•NORTH AMERICAN<br>
                            noun: maven; plural noun: mavens
                          </p>
                          <p>
                            A&nbsp;<strong>maven</strong>&nbsp;is a trusted&nbsp;<a href="https://en.wikipedia.org/wiki/Expert">expert</a>&nbsp;in a particular field, who seeks to pass timely and relevant knowledge on to others in the respective field. The word itself is a borrowing from the&nbsp;<a href="https://en.wikipedia.org/wiki/Yiddish_language">Yiddish</a>&nbsp;<em>meyvn</em>&nbsp;‘an expert, connoisseur’, derived from the&nbsp;<a href="https://en.wikipedia.org/wiki/Hebrew_language">Hebrew</a>&nbsp;<span>מבין</span>&nbsp;<em>mēvīn</em>&nbsp;‘person with understanding, teacher.
                          </p>
                          <p>
                            <strong style="text-decoration: underline;">Synonyms:</strong> ace, adept, champion, genius, hotshot, mavin, sensation, star, superstar, virtuoso, wizard
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          class="synthesis-ui-green-01 pa-4 pa-md-8 pa-lg-8 synthesis-section-border-radius fill-height"
          flat
        >
          <v-container
            class="synthesis-brand-dark-blue--text text-left"
            fluid
          >
            <v-row>
              <v-col cols="12">
                <h1
                  :class="sectionTitleClass"
                  class="white--text mb-8"
                >
                  Awards won by Maven team members over the years
                </h1>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="text-center text-lg-left"
                cols="12"
                lg="5"
                md="7"
                offset-lg="2"
              >
                <v-list
                  class="pa-0 d-inline-block"
                  color="transparent"
                  dark
                  two-line
                >
                  <template v-for="(award, awardIdx) in awards">
                    <v-list-item :key="`award-${awardIdx}`">
                      <v-list-item-avatar>
                        <v-icon x-large>
                          mdi-seal-variant
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          :class="sectionSubTitleClass"
                          class="text-start"
                        >
                          {{ award.Title }}
                        </v-list-item-title>

                        <v-list-item-subtitle class="text-start">
                          {{ award.Subtitle }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-col>

              <v-col
                class="text-center text-lg-left"
                cols="12"
                lg="4"
                md="5"
              >
                <v-card
                  class="d-inline-flex"
                  color="transparent"
                  flat
                >
                  <v-img
                    height="auto"
                    max-width="525"
                    src="https://mavenglobal.co.uk/wp-content/uploads/2019/09/MavenGlobal-215.jpg"
                    width="100%"
                  />
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          class="synthesis-ui-blue-05 pa-4 pa-md-8 pa-lg-8 synthesis-section-border-radius fill-height"
          flat
        >
          <v-container
            class="synthesis-brand-dark-blue--text text-left"
            fluid
          >
            <v-row>
              <v-col cols="12">
                <h1
                  :class="sectionTitleClass"
                  class="white--text mb-8"
                >
                  Management and Advisors
                </h1>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="8"
                offset-md="2"
              >
                <v-list
                  class="pa-0"
                  color="transparent"
                  dark
                  two-line
                >
                  <template v-for="(member, memberIdx) in members">
                    <v-list-item :key="`member-${memberIdx}`">
                      <v-list-item-avatar
                        v-if="!$vuetify.breakpoint.xsOnly"
                        class="rounded align-self-start"
                        size="150"
                        tile
                      >
                        <v-img :src="member.Image" />
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          :class="sectionSubTitleClass"
                          class="text-start"
                        >
                          {{ member.Title }}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                          {{ member.Subtitle }}
                        </v-list-item-subtitle>

                        <div v-if="$vuetify.breakpoint.xsOnly">
                          <v-avatar
                            class="rounded align-self-start mt-4"
                            size="200"
                            tile
                          >
                            <v-img
                              :src="member.Image"
                            />
                          </v-avatar>
                        </div>

                        <div
                          class="mt-4"
                          v-html="member.Body"
                        />
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider
                      v-if="memberIdx < members.length - 1"
                      :key="`member-divider-${memberIdx}`"
                      class="mb-8 mt-4"
                    />
                  </template>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta     from '@/mixins/i18n/i18nRouteMeta'
import CssClassFontSizes from '@/mixins/cssClassFontSizes'

export default {
  name      : 'About',
  components: {},
  directives: {},
  mixins    : [i18nRouteMeta, CssClassFontSizes],
  props     : {},
  dataStore : {},
  enums     : {},
  data () {
    return {
      awards: [
        {
          Title   : 'European Seal of Excellence',
          Subtitle: 'European Commission'
        },
        {
          Title   : 'Best FX Derivatives House of the Year',
          Subtitle: 'Euromoney'
        },
        {
          Title   : 'Currency Derivatives House of the Year',
          Subtitle: 'Risk Magazine'
        },
        {
          Title   : 'Top FX Structuring Team',
          Subtitle: 'Structured Products Magazine'
        },
        {
          Title   : 'Leading Structured Products Team',
          Subtitle: 'MTN-i'
        },
        {
          Title   : 'Best Euro MTN House for FX Linked Structures',
          Subtitle: 'EuroWeek'
        }
      ],

      members: [
        {
          Title   : 'PHILIPPOS KASSIMATIS',
          Subtitle: 'Senior Partner',
          Image   : 'https://mavenglobal.co.uk/wp-content/uploads/2019/08/philipos_kassimatis-300x300.jpg',
          Body    : `
              <p>Philippos is the co-founder of Maven Global. He is also the co-founder of Panthir Capital, an asset management firm nominated twice for Emerging Manager of the Year by S&amp;P’s Capital IQ.</p>
              <p>Previously, he was a Managing Director and Global coHead of FX Structuring at Barclays Capital until 2008. He started his career with Merrill Lynch in New York focusing on M&amp;A /Financings, subsequently responsible for the European Structured business.</p>
              <p>Philippos holds a BA in Engineering from Oxford University and MA Economics / MBA Finance degrees from Yale University. He sits on the boards of Brookstreet Equity Partners and YPO London and is the Deputy Director of the Centre for Innovation and Disruption at Cass Business School.</p>
          `
        },
        {
          Title   : 'ANDY KAUFMANN',
          Subtitle: 'Advisor, Head of Structuring, RBS',
          Image   : 'https://mavenglobal.co.uk/wp-content/uploads/2019/08/andy_kaufmann-300x300.jpg',
          Body    : `
              <p>Andy was the Global Head of Foreign Exchange and Precious Metal Solutions at UBS from 2010 to 2014, leading a team based in London, &nbsp;Zurich, New York, Singapore, Hong Kong and Tokyo. &nbsp;He worked with both corporate and institutional clients, with a focus on risk solutions in the post-<wbr>crisis environment. &nbsp;Previously he was Global co-<wbr>Head of FX Structuring at Barclays Capital, where his team developed new products which enabled a broader client base to access potential opportunities linked to FX. He started his career at NatWest Markets in 1995 as a quantitative analyst on FX Options, with later roles as an FX options trader in London and in New York. &nbsp;He moved to Merrill Lynch in London in 2001, where he subsequently established the FX Structuring function.</p>
              <p>Andy holds an MPhil degree in Economic Theory and Econometrics from Cambridge University &nbsp;and an MA in Mathematics, also from Cambridge University.</p>
          `
        },
        {
          Title   : 'TOM NICHOLAS',
          Subtitle: 'Advisor, William J. Abernathy Professor of Business Administration, Harvard Business School',
          Image   : 'https://mavenglobal.co.uk/wp-content/uploads/2019/11/tom-nicholas.jpg',
          Body    : `
              <p>Tom Nicholas is a Professor in the Entrepreneurial Management Group of Harvard Business School. He holds a doctorate in Economic History from Oxford University. Prior to joining HBS, he taught Technology Strategy at MIT’s Sloan School of Management and technology and finance courses at the London School of Economics. He was also an economics consultant in San Francisco where he performed economic analysis for environmental and antitrust litigation including Sun Microsystems v. Microsoft. At HBS he has taught the first year course, The Entrepreneurial Manager, and he currently teaches in Executive Education programs on entrepreneurship and intellectual property as well as two second year elective courses: The Coming of Managerial Capitalism, which examines entrepreneurship, innovation and business development in the United States over the past 230 years; and Venture Capital in Historical Perspective (with Felda Hardymon), which focuses on the changing organizational structure of the venture capital industry and its impact on entrepreneurship and innovation over time.</p>
              <p>He has received the Faculty Teaching Award in both the Required Curriculum and the Elective Curriculum and the Charles M. Williams Award for teaching excellence.</p>
          `
        },
        {
          Title   : 'COSTAS ANDRIOPOULOS',
          Subtitle: 'Advisor, Associate Dean for Entrepreneurship and Professor of Innovation and Entrepreneurship at Cass Business School, City University London',
          Image   : 'https://mavenglobal.co.uk/wp-content/uploads/2019/11/costas-andriopoulos.jpg',
          Body    : `
              <p>Costas Andriopoulos is the Associate Dean for Entrepreneurship and Professor of Innovation and Entrepreneurship at Cass Business School, City University London.</p>
              <p>His research focuses on innovation, entrepreneurship, and product development. He is the co-author of Managing Change, Creativity and Innovation (3rd Edition, Sage, 2017).</p>
              <p>He is the winner of many teaching and research awards. In addition to his academic work, Professor Andriopoulos has led innovation efforts, consulted companies in private sectors (e.g. Financial Services, New Product Development and Oil) and facilitated several executive education programmes in the areas of strategy and innovation (e.g. Said Business School, University of Oxford).</p>
              <p>Professor Andriopoulos holds masters and doctoral degrees from the University of Strathclyde.</p>
          `
        },
        {
          Title   : 'RICARDO CERVERA',
          Subtitle: 'Advisor, Founding Partner, Vace Partners',
          Image   : 'https://mavenglobal.co.uk/wp-content/uploads/2019/11/ricardo-cervera.jpg',
          Body    : `
              <p>Ricardo Cervera is a Founding Partner at VACE Partners. Prior to founding VACE, Ricardo worked for over 9 years at Salomon Smith Barney and Citigroup.</p>
              <p>Ricardo joined the Investment Banking Division of Salomon Smith Barney in 1999 as a summer associate and then full-time in August 2000. He spent seven years in New York in the Global Power Group, covering US, European and Latin American power companies. Mr. Cervera moved to Citigroup’s Mexico Investment Banking Office as a Director in 2006, and became Head of Mexico Investment Banking in 2007. Ricardo had coverage responsibilities in the power, energy, infrastructure, consumer, retail, industrial and other sectors. Upon arriving to Mexico, he led the privatization of the first Toll-Roads from FARAC worth over US$4 billion; one of the largest privatizations ever in this sector worldwide and winner of Project Finance Latam Deal of the Year in 2008.</p>
              <p>Ricardo currently serves on the Board of Directors of Office Depot Mexico and Concentradora Hipotecaria. From 1995 to 1996, Ricardo worked in Mexico’s Ministry of Finance as an advisor to the General Director of Public Credit and in 1997 became advisor to the Deputy Minister of Finance. Ricardo graduated in the top 1% of his class with a B.A. in Economics from the Instituto Tecnológico Autónomo de México (ITAM) and received an M.B.A. from Yale University. In 1998, Ricardo received Mexico’s National Award in Economic Research.</p>
          `
        },
        {
          Title   : 'MICHAEL KANDARAKIS',
          Subtitle: 'Advisor, Founder, Synaxia Capital',
          Image   : 'https://mavenglobal.co.uk/wp-content/uploads/2019/11/michael-kandarakis.jpg',
          Body    : `
              <p>Michael Kandarakis founded Synaxia Capital in 2014. It is an investment platform pursuing opportunistic transactions in private equity real estate in Europe, typically in partnership with large private equity partners. Michael is also a member of the Management Advisory Board of Towerbrook.</p>
              <p>Prior to that, Michael was a Founder and Senior Partner of Soros Real Estate (later renamed Grove International Partners), a global real estate private equity firm with aggregate equity commitments of over $4.5 billion. He established Grove’s Asia operations, initially focusing on Japan. Grove’s Asia investment platforms successfully completed more than 60 control transactions since 2001, deploying over $1.5bn in equity. Michael sat at the boards of all these companies and oversaw their investment program. He also sat at the Investment and Operating Committees of the firm and co-Chaired the Partnership Committee.</p>
              <p>Prior to joining Grove, Mr. Kandarakis worked at Morgan Stanley in London and New York, and was a member of the team that initiated MSREF’s European investment program. In parallel he worked on numerous investment banking and securitization transactions, helping to initiate these programs in both Northern and Southern Europe.</p>
              <p>He graduated with honours from the London School of Economics and Political Science where he received a B.S. in Management.</p>
          `
        },
        {
          Title   : 'MAKIS KAKETSIS',
          Subtitle: 'Advisor, Founder, MSK-NY',
          Image   : 'https://mavenglobal.co.uk/wp-content/uploads/2023/06/kaketsis-3.jpg',
          Body    : `
              <p>Makis (Mike) Kaketsis is the Founder and CEO of MSK-NY. He has held the roles of a CIO and CEO across multiple firms as well as being a successful entrepreneur.</p>
              <p>He has over twenty two years of experience across a range of investment strategies and asset classes. He has managed portfolios across equities, commodities, fixed income, and currencies. He has experience implementing both fundamental and quantamental strategies. He is passionate about the use of derivatives to limit downside and maintain upside to create asymmetric expressions of investment ideas.</p>
              <p>Makis career as a portfolio manager includes Deutsche Asset Management, F&amp;C Asset Management, Ivaldi Capital LLP, MSK Capital Partners LLP, Melchior-Dalton and SCM Capital LP.</p>
              <p>Makis has experience ranging from growing start ups and establishing spin-offs, to working in large global institutions and effecting successful exits on the two firms he founded. He has worked with and created distribution partnerships with both bulge bracket banks and large asset managers.</p>
              <p>Makis holds a bachelors of Science in Economics from the London School of Economics and a Master of Philosophy in Economics from the University of Oxford. Makis is based in New York.</p>
          `
        }
      ]
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>

</style>
