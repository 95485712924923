<template>
  <div class="">
    <v-app-bar
      app
      class="px-0 px-md-2"
      clipped-left
      color="synthesis-brand-dark-blue"
      dark
      dense
      flat
      height="80"
    >
      <div class="d-flex align-center fill-height">
        <router-link :to="{name: 'Home'}">
          <img
            :height="$vuetify.breakpoint.smAndDown ? 36 : 40"
            :src="$vuetify.breakpoint.smAndDown ? '/img/logo/logo-symbol.png' : '/img/logo/logo-light.png'"
          >
        </router-link>
      </div>

      <div class="flex-grow-1" />

      <v-btn
        :height="$vuetify.breakpoint.smAndDown ? 36 : 42"
        :min-width="$vuetify.breakpoint.xsOnly ? 'auto' : 110"
        class="white--text mr-2"
        color="synthesis-ui-green-02"
        depressed
        href="https://app.mavensynthesis.com/"
        target="_blank"
        v-text="$t('Common.Button.Login')"
      />

      <v-btn
        :height="$vuetify.breakpoint.smAndDown ? 36 : 42"
        :min-width="$vuetify.breakpoint.xsOnly ? 'auto' : 110"
        class="synthesis-brand-dark-blue--text"
        color="white"
        depressed
        @click="$bus.$emit('Dialog:Contact:Show')"
        v-text="$t('Common.Button.GetStarted')"
      />
    </v-app-bar>
  </div>
</template>

<script>

export default {
  name      : 'AppToolbar',
  components: {},
  directives: {},
  mixins    : [],
  props     : {},
  dataStore : {},
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>
/deep/ .v-toolbar__content {
  padding-top    : 0;
  padding-bottom : 0;
  height         : 100% !important;
}
</style>
